<template>
  <div class="report-page-wrapper">
    <h1 class="requests-header">{{ $t('Reports') }}</h1>
    <div class="requests-filter-and-create">
      <div class="requests-filter-section">
        <div class="search">
            <div class="form-field md-layout-item">
                <md-field>
                    <md-select
                        name="selectedClient"
                        class="select-client"
                        v-model="selectedClient"
                        @md-selected="filter"
                        placeholder="Client"
                    >
                        <md-option
                            v-for="(item, i) in clients"
                            :key="i"
                            :selected="selectedClient === item.id"
                            :value="item.id"
                        >
                            {{ $t(item.name) }}
                        </md-option>
                    </md-select>
                </md-field>
            </div>

            <div class="sign-event filter-status-wrap filter-date-wrap">
                <md-datepicker v-model="request_date_from"  @input="filter">
                    <label v-if="!request_date_from">{{ $t('From date') }}</label>
                    <label v-else></label>
                </md-datepicker>
            </div>
            <div class="sign-event filter-status-wrap filter-date-wrap">
                <md-datepicker v-model="request_date_to"  @input="filter">
                    <label v-if="!request_date_to">{{ $t('To date') }}</label>
                    <label v-else></label>
                </md-datepicker>
            </div>
        </div>
      </div>
      <div class="crate-wrapper">
        <router-link to="/requests/create" v-if="hasPermission('requests_create')">
          <button class="btn-create">
            <p>Create</p>
            <img src="../../assets/images/Path.png" alt="arrow">
          </button>
        </router-link>
      </div>
    </div>
    <div class="table-wrapper" v-if="this.userData && this.userData.roles[0].name !== 'client'">
      <md-table>
        <md-table-row class="head_row">
          <md-table-head
              v-for="(item, i) in headData"
              :key="i"
          >
            <md-ripple class="md-table-title">
              <div @click="customSorting(item.name, initialSort, i)">

                {{ $t(item.title) }}
                <img class="md-size-2x arrow_down" :class="`arrow_down_${i}`" src="../../assets/images/arrow_down.svg"/>
              </div>
            </md-ripple>
          </md-table-head>
        </md-table-row>
        <md-table-row v-for="item in requests" :key="item.id">
          <md-table-cell>{{ item.title }}</md-table-cell>
          <md-table-cell>{{ $t(item.nature) }}</md-table-cell>
            <md-table-cell>{{ item.request_date }}</md-table-cell>
            <md-table-cell>{{ getReadableDate(item.answer_date) }}</md-table-cell>
        </md-table-row>
      </md-table>
        <div class="recDiv"></div>
        <div class="recCount">
            <p>{{ $t('Number of requests') }}</p>
                <p>: {{reqCount}}</p>
        </div>
        <br>
        <div class="reqCountTotals">
            <div class="recCount">
                <p>{{ $t('Person level') }} 1</p>
                <p>: {{personLevel1}}</p>
            </div>
            <div class="recCount">
                <p>{{ $t('Person level') }} 2</p>
                <p>: {{personLevel2}}</p>
            </div>
            <div class="recCount">
                <p>{{ $t('Person level') }} 3</p>
                <p>: {{personLevel3}}</p>
            </div>
            <div class="recCount">
                <p>{{ $t('Person level') }} 4</p>
                <p>: {{personLevel4}}</p>
            </div>
            <div class="recCount">
                <p>{{ $t('Organization') }}</p>
                <p>: {{natureOrganization}}</p>
            </div>
            <div class="recCount">
                <p>{{ $t('Financial') }}</p>
                <p>: {{natureFinancial}}</p>
            </div>
            <div class="recCount">
                <p>{{ $t('Other') }}</p>
                <p>: {{natureOther}}</p>
            </div>
        </div>

      <custom-pagination
          :list="requests"
          :page="pagination.current_page"
          :pagesLength="pagesLength"
          :changePage="changePage"
      />
      <md-dialog-alert
          :md-active.sync="showAlert"
          md-content="The 'To date' must be superior or equal to the 'From date'"
          md-confirm-text="Close" />
    </div>

  </div>
</template>

<script>
import {userPermissions} from '@/mixins/userPermissions';
import {mapActions, mapGetters} from "vuex";
import CustomPagination from "../../components/pagination/CustomPagination";

export default {
  name: "requests-list",
  components: {CustomPagination},
  data: () => ({
    title: '',
    selectedDate: null,
    selected: [],
    selectedStatus: [],
    searchData: '',
    requests: [],
    selectedClient: 'all',
    clients: [],
    request_date_from: '',
    request_date_to: '',
    permissions: [],
    pagination: {
      current_page: null,
      from: null,
      last_page: null,
      per_page: null,
      total: null
    },
    initialSort: "desc",
    sortName: '',
    sort: '',
    headData: [
      {title: 'Title', name: 'title', asc: false, desc: true},
      {title: 'Nature', name: 'nature', asc: false, desc: true},
      {title: 'Creation date', name: 'created_at', asc: false, desc: true},
      {title: 'Answer date', name: 'answer_date', asc: false, desc: true},
    ],
    showAlert: false
  }),
  mixins: [
    userPermissions
  ],

  async mounted() {
    await this.getUserInfo()
    await this.getRequestClients();
    await this.getStatusesList()
    await this.getPaginatedList({})
    await this.getUsersList()
    await this.updateRequestData()
  },
  methods: {
      async updateRequestData(data) {
          let client,dateFrom,dateTo;
          if(data && data.client){
              client = data.client
          }else{
              client = null
          }
          if(data && data.dateFrom){
              dateFrom = data.dateFrom
          }else{
              dateFrom = null
          }
          if(data && data.dateTo){
              dateTo = data.dateTo
          }else{
              dateTo = null
          }
          await this.getPersonLevel1({type: 'Person level 1', client: client, dateFrom: dateFrom, dateTo: dateTo})
          await this.getPersonLevel2({type: 'Person level 2', client: client, dateFrom: dateFrom, dateTo: dateTo})
          await this.getPersonLevel3({type: 'Person level 3', client: client, dateFrom: dateFrom, dateTo: dateTo})
          await this.getPersonLevel4({type: 'Person level 4', client: client, dateFrom: dateFrom, dateTo: dateTo})
          await this.getNatureOrganization({type: 'Organization', client: client, dateFrom: dateFrom, dateTo: dateTo})
          await this.getNatureFinancial({type: 'Financial',  client: client, dateFrom: dateFrom, dateTo: dateTo})
          await this.getNatureOther({type: 'Other', client: client, dateFrom: dateFrom, dateTo: dateTo})
      },
    async customSorting(sortName, sort, i) {
      if (this.initialSort === "desc") {
        document.querySelector(`.arrow_down_${i}`).style.transform = 'rotate(180deg)'
        this.initialSort = "asc"
      } else {
        document.querySelector(`.arrow_down_${i}`).style.transform = 'rotate(0deg)'
        this.initialSort = "desc"
      }
      this.headData.map(item => {
        if (sort === 'desc' && item.name === sortName) {
          item.desc = false
          item.asc = true
        } else {
          item.asc = false
          item.desc = true
        }
      })

      this.sortName = sortName
      this.sort = sort
        await this.getPaginatedList({
          page: this.pagination.current_page,
          selectedClient: this.selectedClient,
          request_date_from: this.request_date_from,
          request_date_to: this.request_date_to,
          sort: sortName,
          sortBy: sort
        })
    },
    async getPaginatedList({page = 1, selectedClient = '',title = '', description = '', answer_id = '', request_date_from = '', request_date_to = '', sort = '', sortBy = 'desc'}) {
      await this.getRequestsList({page, selectedClient, title, description, answer_id, request_date_from, request_date_to, sort, sortBy})
          .then(data => {
            this.requests = data
            this.pagination.current_page = this.reqPagination.current_page
            this.pagination.from = this.reqPagination.from
            this.pagination.last_page = this.reqPagination.last_page
            this.pagination.per_page = this.reqPagination.per_page
            this.pagination.total = this.reqPagination.total
          })
    },
    ...mapActions(['getRequestsList', 'getDeleteRequest', 'getUserInfo', 'getStatusesList', 'getUsersList', 'getPermissionsList', 'getPersonLevel1', 'getPersonLevel2', 'getPersonLevel3', 'getPersonLevel4', 'getNatureOrganization', 'getNatureFinancial', 'getNatureOther']),

    async changePage(currentPage) {
        await this.getPaginatedList({
          page: currentPage,
          selectedClient: this.selectedClient,
          request_date_from: this.request_date_from,
          request_date_to: this.request_date_to,
          sort: this.sortName,
          sortBy: this.sort
        })
    },

    async filter() {
      if (this.$moment(this.request_date_from).diff(this.$moment(this.request_date_to)) >= 1) {
        this.showAlert = true
      } else {
        await this.getPaginatedList({
          page: 1,
          selectedClient: this.selectedClient,
          request_date_from: this.request_date_from,
          request_date_to: this.request_date_to,
          sort: this.sortName,
          sortBy: this.sort
        })
      }

      const data = {
          client: (!this.selectedClient || this.selectedClient === 'all') ? null : this.selectedClient,
          dateFrom: this.request_date_from ?? null,
          dateTo: this.request_date_to ?? null,
      }

        await this.updateRequestData(data)
    },

      getReadableDate(date) {
        if (date){
            return this.$moment(date).format('YYYY-MM-DD')
        }else return null
      },

    async axiosHandler(id) {
      const deletedRequest = await this.getDeleteRequest(id)
      if (deletedRequest.status === 'success') {
          this.requests = await this.getRequestsList({
            page: 1,
            selectedClient: this.selectedClient,
            request_date_from: this.request_date_from,
            request_date_to: this.request_date_to,
            sort: this.sortName,
            sortBy: this.sort
          })

      }
    },
    async handlerDelete(id = null) {
      let conf = confirm("Are you sure?");

      if (!conf) {
        return;
      }

      if (id != null) {
        await this.axiosHandler(id)

      } else {
        let ids = this.selected;
        let actions = [];

        ids.map(id => {
          actions.push(
              this.axiosHandler(id)
          )
        });
        try {
          await Promise.all(actions)

        } catch (e) {
          console.log(e)
        }
      }

    },

      async getRequestClients() {
          await this.$axios
              .get(process.env.VUE_APP_API_URL + '/clients/request')
              .then(response => {
                  this.clients = response.data
                  if (this.clients.length) {
                      this.clients.unshift({name: 'All clients', id: 'all'})
                  }

              })
              .catch(error => {
                  console.log('error', error)
              });
      },

    hasPermission(name) {
      return this.userPermissions.indexOf(name) >= 0
    },

  },

  computed: {
    ...mapGetters(['requestsList', 'userData', 'statusesList', 'reqPagination', 'reqCount', 'personLevel1', 'personLevel2', 'personLevel3', 'personLevel4', 'natureOrganization', 'natureFinancial', 'natureOther']),
    pagesLength() {
      return Math.ceil(+this.pagination.total / +this.pagination.per_page)
    }
  },
}
</script>

<style lang="scss">

.md-checkbox .md-checkbox-container {
  width: 20px;
  min-width: 20px;
  height: 20px;
  position: relative;
  transition: .4s cubic-bezier(.25, .8, .25, 1);
  border: 1px solid #CDD0D9 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
}

.md-table-head-label {
  color: #000000 !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.md-table-title > div {
  color: #000000 !important;
  font-weight: bold;
}

.arrow_down:hover {
  cursor: pointer;
}

.arrow_down {
  height: 18px !important;
  overflow: auto;
  transition: 0.25s ease;
}

.head_row {
  border-bottom: 1.5px solid #000000 !important;
}

.md-checkbox.md-theme-default.md-checked.md-primary .md-checkbox-container {
  background-color: #054F63 !important;
}

.md-checkbox.md-theme-default.md-checked .md-checkbox-container {

  background-color: #054F63 !important;
}

.report-page-wrapper {
  padding: 0 20px;

  .requests-header {
    font-weight: 900;
    font-size: 36px;
    line-height: 52px;
    color: #000;
    display: flex;
    justify-content: flex-start;
    margin: 0 0 16px;
  }

  .requests-filter-and-create {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .requests-filter-section {
    display: flex;
    align-items: center;
  }

  .start-date {
    margin-left: 20px !important;
  }

  .md-card-content {
    width: 260px;
    margin-left: 20px;
    padding: 0 !important;
  }

  .table-wrapper {
    padding-right: 20px;
  }

  .md-table-cell:last-child .md-table-cell-container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .md-table-cell-container {
    padding: 6px 25px 6px 21px !important;
  }

  .search-selects {
    position: absolute;
    left: 15px;
  }

  .search {
    display: flex;

    &-icon {
      margin-left: 20px;
      width: 20px !important;
    }
  }

  .filter-status-wrap {
    margin-left: 15px;

    select {
      width: 250px;
    }
  }

  .select-field {
    background: #FFFFFF;
    border-radius: 4px;
    height: 48px;
    padding: 0 0 0 5px;
    margin: 0;
    width: 250px;
  }

  .md-button {
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 6px;
    width: 30px !important;
    height: 30px;
    min-width: unset;
  }


  .line {
    margin: 0 10px;
  }

  .crate-wrapper {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  .md-field {
    width: 260px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0 20px 0 0;
    padding-top: 0;

    &::after {
      background-color: unset !important;
      position: unset !important;
    }

    .md-input {
      height: unset !important;
      width: 100%;
      padding-left: 10px;
    }
  }

  .btn-create {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 146px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    border-radius: 6px;
    margin-left: 10px;
    cursor: pointer;

  }

  .btn-create:hover {
    background: #F7F7F8;
  }

  .btn-create p {
    margin: 0;
    padding: 0 15px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;

  }


  .date-picker {
    width: 285px;
    height: 48px;
    border: 1px solid #CDD0D9 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
  }

  .md-table-head:last-child:not(:first-child) .md-table-head-label {
    justify-content: center !important;
  }

  .actions-section {
    .md-table-cell-container {
      display: flex !important;
      justify-content: center !important;
    }
  }

  .md-field > .md-icon {
    margin: auto !important;
  }

  .md-button {
    min-width: unset !important;
  }

  .md-table.md-theme-default .md-table-content, .md-table.md-theme-default .md-table-alternate-header {
    border: none !important;
    height: unset !important;
    max-height: unset !important;
  }

  .md-card {
    box-shadow: unset !important;
  }

  .md-button.md-theme-default.md-raised:not([disabled]).md-accent {
    background-color: unset !important;
  }

  .md-button.md-theme-default.md-raised:not([disabled]).md-accent .md-icon-font {
    color: rgba(0, 0, 0, 0.54) !important;
  }

  .md-checkbox.md-theme-default .md-checkbox-container {
    border: 1px solid #CDD0D9 !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
  }

  .report .md-button {
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 6px;
    width: 36px !important;
    height: 36px;
    min-width: unset;
  }

  .report-page-wrapper .md-table-sortable-icon svg {
    fill: #EC8B31 !important;
    height: 18px !important;
  }

  .report-page-wrapper .md-ripple, .report-page-wrapper .md-table-cell-container {
    font-weight: normal;
    font-size: 15px;
    line-height: 32px;
    color: #000000;
    text-align: left;
  }

  .md-table-sortable-icon {
    opacity: 1 !important;

    svg {
      fill: #EC8B31 !important;
      height: 18px !important;
    }
  }

  .md-table.md-theme-default .md-table-content, .md-table.md-theme-default .md-table-alternate-header {
    height: unset !important;
    max-height: unset !important;
  }

  .md-table {
    margin-top: 30px;
    box-shadow: unset !important;
  }

  .md-table-fixed-header-container > table {
    width: 100% !important;
  }

  .md-table + .md-table {
    margin-top: 16px
  }

  .md-ripple, .md-table-cell-container {
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
    text-align: left;
  }

  .md-table-fixed-header-container > table > thead > tr {
    border-bottom: 1px solid #000000;
  }

  .md-checkbox .md-checkbox-container {
    border: 1px solid #CDD0D9 !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
  }

  .md-table-cell-selection .md-checkbox {
    margin: 0 47px 0 37px;
  }

  .md-table-cell-selection .md-checkbox .md-checkbox-container:after {
    top: 0 !important;
    left: 5px !important;
  }

  .md-table-cell {
    &:last-child .md-table-cell-container {
      display: flex;
    }
  }

  //.md-table-head-label {
  //  font-weight: bold;
  //  font-size: 16px;
  //  padding-right: unset !important;
  //  padding-left: 18px !important;
  //  width: 100% !important;
  //  height: 100% !important;
  //}

  .md-table-head-container {
    padding-top: 10px !important;
    padding: unset;
  }

}
</style>
<style lang="scss" >
.report-page-wrapper {
    .md-icon-button:before {
        border-radius: 0%;
    }
  .md-table-head-label {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    padding-right: unset !important;
    padding-left: 22px !important;
    width: 100% !important;
    height: 100% !important;
  }
    .recCount{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 6px 13px;
        background: #FFFFFF;
        border: 1px solid #CDD0D9;
        box-sizing: border-box;
        border-radius: 20px;
        width: fit-content;
        margin-left: 15px;
    }
    .recDiv{
        border-top: 1px solid gray;
        padding-top: 30px;
    }
    .reqCountTotals{
        display: flex;
    }
    .md-datepicker {
        align-items: center;

        .md-icon {
            margin-left: 3px!important;
            left: 0;
        }

        svg {
            fill: #054F63 !important;
        }
        .md-input {
            padding: 0!important;
        }

    }
    .sign-event{
        margin-left: -10px;
    }
    .filter-date-wrap{

        .md-input-action{
            top: 8px;
            right: 6px;
        }
        .md-button{
            border: none;
        }
        .md-field>.md-icon~label {
            left: 56px;
            top: 14px;
        }
    }
    .filter-sign-wrap{
        .md-field{
            width: 56px;
        }
        .md-icon-image{
            left: 57%!important;
        }
        .md-select-menu{
            min-width: 53px!important;
            max-width: 72px!important;
        }
    }
}
</style>
